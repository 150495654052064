/* .featured-ctn {
  width: 50%;
  margin: 150px auto;
} */

.featured-ctn > div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  display:-webkit-flex
}

.featured-ctn svg {
  width: 20px;
  margin: 50px;
}
