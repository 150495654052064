body {
  overflow-x: hidden;
}

#root, body {
  margin: 0;
  background-color: #090909;
}

.content {
  width: 50%;
  margin: 150px auto;
}

h1, h2, h3, h4, h5 {
  font-family: dunbar-low, sans-serif;
  font-weight: 400;
  font-style: normal;
}

video {
  width: 100%;
}

.half-width {
  height: 500px;
  display: flex;
  flex-wrap: nowrap;
  width: 70%;
  margin: 0 auto 150px;
}

.half-width .left-col {
  height: 500px;
  width: 50%;
  background-color: blue;
}

.half-width .right-col {
  height: 500px;
  width: 50%;
  background-color: red;
}
