.full-width {
  width: 80%;
  margin: -200px auto 150px;
  display: flex;
  height: 100vh;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.big {
  font-size: 86px;
  font-family: dunbar-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #fff;
}

.full-width svg {
  width: 150px;
  transform-origin: center;
}
