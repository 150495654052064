.header {
  background-color: #090909;
  max-height: 300px;
  padding: 10px 30px 10px 10px;
  display: flex;
  justify-content: space-between;
}

.logo-light {
  width: 400px;
}

.main-nav {
  text-align: right;
}

.main-nav li {
  font-family: ff-basic-gothic-pro, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #fff;
  list-style: none;
  margin-bottom: 5px;
  font-size: 18px;
}

.logo-container .cls-1 {
  fill: none !important;
}
